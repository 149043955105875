<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Purchase Order To Bill"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <DateQuerySetter @onClickGo="getPOList">
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <label for="colFormLabel" class="col-form-label">PO Number</label>
          <input class="form-control" type="text" name="po_number" v-model="poNumber">
        </div>

        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <label for="colFormLabel" class="col-form-label">Bill Number</label>
          <input class="form-control" type="text" name="bill_number" v-model="billNumber">
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label">Supplier</label>
          <vField v-model="supplierId" class="d-none" name="supplier_id"/>
          <v-select
              placeholder="Select Supplier"
              v-model="supplierId"
              :options="contacts"
              label="name"
              :reduce="name => name.id"
          />
        </div>

      </DateQuerySetter>
    </div>

    <ListTable :po-list="poList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>
  <GlobalLoader/>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/purchase-order/purchase-order-entry/POBillListingTable.vue'
import Pagination from '@/components/atom/Pagination'
import {
  inject,
  ref,
  onMounted,
  computed,
  watch
} from 'vue';
import {
  useRoute,
  useRouter
} from "vue-router";
import handleContact from '@/services/modules/contact'
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleProjects from "@/services/modules/procurement/project";
import {useStore} from "vuex";

const router  = useRouter()
const route   = useRoute()
const loading = ref(false);
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchContactProfiles} = handleContact()
const {fetchPOList} = handleRequisitions();
const {fetchProjects} = handleProjects();

let poList = ref({});
let companyId = computed(() => route.params.companyId);
const user = computed(() => {
  return store.state.user.user.name;
})
const offset = ref(10);
let page = ref(1);
const loader = ref(false);
let contacts = ref([])
let projects = ref([])
let allStatus = ref([
  {
    id  : '',
    name: 'All'
  },
  {
    id  : 'pending',
    name: 'Pending'
  },
  {
    id  : 'approved',
    name: 'Approved'
  }
])
let supplierId = ref(null)
let poNumber = ref(null)
let billNumber = ref(null)
let status = ref(null)

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  if(supplierId.value) query += `&supplier_id=${supplierId.value}`;
  if(poNumber.value) query += `&po_number=${poNumber.value}`;
  if(billNumber.value) query += `&bill_number=${billNumber.value}`;
  query += '&page=' + route.query.page ?? page.value
  return query
}

function onClickAddNewButton() {
  let param = {
    companyId: route.params.companyId,
    moduleId : route.params.moduleId,
    menuId   : route.params.menuId,
    pageId   : route.params.pageId,
  }

  let query = route.query;
  query.type = 'purchase-order-bill';

  router.push({
    name  : 'add-new-po',
    params: param,
    query : query,
  })
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

async function getPOList() {
  try {
    const res = await fetchPOList(getQuery());

    if (! res.status){
      poList.value = {};
      return false;
    }

    poList.value = res.data;
    setPaginationData({
      records: res.data.total,
      perPage: res.data.per_page,
      currentPage: res.data.current_page,
    })

  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

function onPageChange(page) {
  let routeQuery  = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  getPOList();
}

onMounted(async () => {
  loader.value       = true
  const projectsRes = fetchProjects(getQuery());
  const companyQuery = `?company_id=${companyId.value}`
  const contactRes   = fetchContactProfiles(companyQuery)
  await Promise.all([
    projectsRes.then(res => {
      if(res.data) projects.value = res.data;
    }),
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
  ]).then(() => {
    loader.value = false;
  }).catch((err) => {
    loader.value = false
  });

  await getPOList();
})
</script>
