<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>PO No</th>
          <th>PO Date</th>
          <th>Supplier</th>
          <th>Bill Number</th>
          <th>Bill Date</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in poList.data" :key="i">
          <td>
            <button @click="printPO(item)" class="btn btn-outline-success btn-sm mr-5">
              <span>{{ item.po_number }}</span>
            </button>
          </td>
          <td>{{ item.date }}</td>
          <td>{{ item.contact_profile.full_name || '' }}</td>
          <td>
            <button v-if="item.pm_id" @click="goToPrint(item.pm_id)" class="btn btn-outline-success btn-sm mr-5">
              <span>{{ item.pm_bill_number }}</span>
            </button>
          </td>
          <td>{{ item.pm_date }}</td>
          <td>
            <button v-if="item.pm_id" class="btn btn-secondary btn-sm" title="Edit Purchase Bill"
                    @click="editPurchaseBill(item)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-edit"></i> Edit Bill
            </button>
            <button v-if="!item.pm_id" class="btn btn-primary btn-sm" title="Edit PO"
                    @click="purchaseBill(item)"
                    style="cursor: pointer"
            >
              <i class="fas fa-plus"></i> Bill
            </button>
            <button v-if="!item.pm_id" class="btn btn-secondary btn-sm" title="Edit PO"
                    @click="editPO(item)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-edit"></i> Edit PO
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!poList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import handleCompany from "@/services/modules/company";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleBarcode from "@/services/modules/barcode";
import poPdfPrinter from '@/services/utils/poPdfPrinter';
import pdfPrinter from '@/services/utils/pbPdfPrinter';
import handlePurchase from '@/services/modules/purchase'

import { formatDate } from '@/services/utils/global';
import {inject, reactive, ref} from "vue";
import { useStore } from "vuex";
inject('showError')
const { fetchCompanyInfo } = handleCompany();
const { fetchSinglePO } = handleRequisitions();
const { fetchBarcode } = handleBarcode();
const { exportToPDF:pdExportToPDF } = pdfPrinter();
const { exportToPDF } = poPdfPrinter();
const { fetchPurchaseBill  } = handlePurchase()

const routeList = {
  cs_po: "po-add",
  pr_po: "pr-po-add",
  new_po: "add-new-po"
}

const props  = defineProps({
  poList: Object,
});

let companyInfo = reactive({})
const route  = useRoute();
const router = useRouter();
const store = useStore();
let purchaseOrder = reactive({})
let barcodeQuery = ref('');

const barcode = ref('');
const qrcode = ref('');

function editPO(item) {
  let param = {
    companyId: route.params.companyId,
    moduleId : route.params.moduleId,
    menuId   : route.params.menuId,
    pageId   : route.params.pageId,
  }
  
  let query = route.query;
  query.poId = item.id;

  if(! routeList.hasOwnProperty(item.po_type)) {
    showError("Expected purchase order type not found")
    return;
  }

  router.push({
    name  : routeList[item.po_type],
    params: param,
    query : query
  })
}

function editPurchaseBill(item) {
  let param = {
    companyId: route.params.companyId,
    moduleId : route.params.moduleId,
    menuId   : route.params.menuId,
    pageId   : route.params.pageId,
    purchaseId: item.pm_id
  }

  let query = route.query;
  query.type = 'purchase-order-bill';

  router.push({
    name  : 'purchase-bill-edit-2',
    params: param,
    query : query
  })
}

function purchaseBill(item) {
  let param = {
    companyId: route.params.companyId,
    moduleId : route.params.moduleId,
    menuId   : route.params.menuId,
    pageId   : route.params.pageId,
  }

  let query = route.query;
  query.poId = item.id;
  query.type = 'purchase-order-bill';
  router.push({
    name  : 'purchase-bill-pharmacy',
    params: param,
    query : query
  })
}

async function printPO(item) {

  const user = store.state.user.user;
  const companyQuery = `?company_id=${route.params.companyId}`;

  await fetchSinglePO(item.id, companyQuery).then((res) => {
    if (res.status) {
      
      purchaseOrder = res.data;
      
      let qrcodeData = JSON.stringify({
        po_number: purchaseOrder.po_number,
        po_date: purchaseOrder.po_date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      
      barcodeQuery.value = `?code=${purchaseOrder.po_number}&qrcode=${qrcodeData}`;

    }
  })
    .then(() => {
      fetchBarcode(barcodeQuery.value).then((res) => {
        barcode.value = res.barcode;
        qrcode.value = res.qrcode
      }).then(() => {
        exportToPDF(companyInfo, purchaseOrder, barcode.value, qrcode.value, user.name, item.po_type)
      })
    })
    .catch((err) => {
      console.log("Something went wrong");
    });

}

const goToPrint = (id) => {
  const user = store.state.user.user
  const companyQuery = `?company_id=${route.params.companyId}`;
  let barcodeQuery = "";
  let qrcode = null
  let barcode = null
  let purchaseBill = null

  fetchPurchaseBill(id, companyQuery).then( (res) =>{
    if( res ){
      purchaseBill = res.data;
      let qrcodeData = JSON.stringify({
        ref_no: purchaseBill.bill_number,
        ref_date: purchaseBill.date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery = `?code=${purchaseBill.bill_number}&qrcode=${qrcodeData}`;
    }}).then (() => {
    fetchBarcode(barcodeQuery).then( (res) => {
      barcode = res.barcode;
      qrcode = res.qrcode
    }).then( () => {
      pdExportToPDF(companyInfo, purchaseBill, barcode, qrcode, user.name, 1)
    })
  }).catch( (err) => {
    console.log(err);
  })
}

const setCompanyInfo = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

setCompanyInfo();
</script>
